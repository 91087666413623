.margin-0 {
  margin: 0px; }

.margin-top-0 {
  margin-top: 0px; }

.margin-right-0 {
  margin-right: 0px; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.margin-left-0 {
  margin-left: 0px; }

.margin {
  margin: 8px; }

.margin-top {
  margin-top: 8px; }

.margin-right {
  margin-right: 8px; }

.margin-bottom {
  margin-bottom: 8px; }

.margin-left {
  margin-left: 8px; }

.margin-2 {
  margin: 16px; }

.margin-top-2 {
  margin-top: 16px; }

.margin-right-2 {
  margin-right: 16px; }

.margin-bottom-2 {
  margin-bottom: 16px; }

.margin-left-2 {
  margin-left: 16px; }

.padding-0 {
  padding: 0px; }

.padding-top-0 {
  padding-top: 0px; }

.padding-right-0 {
  padding-right: 0px; }

.padding-bottom-0 {
  padding-bottom: 0px; }

.padding-left-0 {
  padding-left: 0px; }

.padding {
  padding: 8px; }

.padding-top {
  padding-top: 8px; }

.padding-right {
  padding-right: 8px; }

.padding-bottom {
  padding-bottom: 8px; }

.padding-left {
  padding-left: 8px; }

.padding-2 {
  padding: 16px; }

.padding-top-2 {
  padding-top: 16px; }

.padding-right-2 {
  padding-right: 16px; }

.padding-bottom-2 {
  padding-bottom: 16px; }

.padding-left-2 {
  padding-left: 16px; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.text-primary {
  color: #007bff !important; }

.border-primary {
  border-color: #007bff !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.text-secondary {
  color: #6c757d !important; }

.border-secondary {
  border-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.text-success {
  color: #28a745 !important; }

.border-success {
  border-color: #28a745 !important; }

.bg-error {
  background-color: #dc3545 !important; }

a.bg-error:hover, a.bg-error:focus,
button.bg-error:hover,
button.bg-error:focus {
  background-color: #bd2130 !important; }

.text-error {
  color: #dc3545 !important; }

.border-error {
  border-color: #dc3545 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.text-warning {
  color: #ffc107 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.text-info {
  color: #17a2b8 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.text-dark {
  color: #343a40 !important; }

.border-dark {
  border-color: #343a40 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.text-light {
  color: #f8f9fa !important; }

.border-light {
  border-color: #f8f9fa !important; }

html,
body {
  margin: 0; }

* {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; }

a {
  text-decoration: none; }

.starting img {
  width: 70px;
  height: 70px; }

.app-error {
  position: absolute;
  z-index: 999999;
  background-color: maroon;
  top: 0;
  left: 0;
  right: 0;
  padding: 5px 10px;
  color: white;
  text-align: center;
  font-size: 0.85em; }
  .app-error a {
    color: #05a1bf;
    text-decoration: none; }

.clickable {
  cursor: pointer; }

.hidden {
  display: none; }

.markdown-body {
  color: inherit; }

.screen-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; }
  .screen-center > * {
    margin-top: -10rem; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.font-xs {
  font-size: 0.5em; }

.font-sm {
  font-size: 0.75em; }

.font-lg {
  font-size: 1.25em; }

.font-xl {
  font-size: 1.5em; }

.font-b {
  font-weight: bold; }

.text-center {
  text-align: center; }

.dark .markdown-body pre {
  background-color: #424242; }

/* @bndynet/dialog */
.bn-loading-overlay .bn-loading-container .bicon.bicon-loading {
  border-left-color: #303f9f; }

/* TODO: remove after the dialog bugfix */
.notification-item {
  max-width: 360px; }
